import React from 'react';
import Link from "gatsby-link"
import logo from '../img/logo-white-all.png';
import "./Footer.scss";

function Footer(){

    return (
        <>
            <footer className="footer-area footer-2 bg_cover">
            <div className="container">
                <div className="footer-widget pt-30 pb-70">
                    <div className="row">
                        <div className="text-center col-sm-4 order-sm-1 order-lg-1">
                            <div className="footer-contact pt-40">
                                <a href="#logo" id="logo-container">
                                    <img src={logo} width="50%" alt="Logo" />
                                </a>
                                <ul className="social mt-40">
                                        <li><a target="_blank" href="https://www.facebook.com/lexiqa"><i className="lni-facebook"></i></a></li>
                                        <li><a target="_blank" href="https://www.linkedin.com/company/lexiqa/"><i className="lni-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="text-center col-sm-4 order-sm-3 order-lg-2">
                            <div className="footer-link pt-40">
                                <div className="footer-title">
                                    <h5 className="title">COMPANY</h5>
                                </div>
                                <ul>
                                    <li>
                                        <Link to="/pricing">Pricing</Link>
                                    </li>
                                    <li>
                                        <Link to="/legal">Legal notice</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy">Privacy policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/security">Data Privacy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="text-center col-sm-4 order-sm-2 order-lg-4">
                            <div className="footer-link pt-40">
                                <div className="footer-title">
                                    <h5 className="title">RESOURCES</h5>
                                </div>
                                <ul>
                                    <li>
                                        <Link to="/updates">Product Updates</Link>
                                    </li>
                                    <li>
                                        <Link to="/blog">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/learning-centre">Learning Centre</Link>
                                    </li>
                                    <li>
                                        <a 
                                        rel="noopener noreferrer"
                                        href="https://backend.lexiqa.net/docs/documentation/" 
                                        target="_blank">Documentation</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright text-center">
                    <p className="text">&copy; 2024 Lexiqa Limited - all rights reserved</p>
                </div>
            </div>
            </footer>
        </>
    );

}

export default Footer;